import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  ListSubheader,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Editor } from '@tiptap/react';
import { SearchInput, useDisclosure } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { ChevronDownIcon } from '@tyro/icons';
import { useMemo, useRef, useState } from 'react';
import { useVariablesQuery } from '../../api/variables';
import { contextValues } from './template-editor-container';

type VariableSelectorProps = {
  contextValue: string;
  editor: Editor;
};

type ChipValueTypes = {
  label: string;
  value: string;
};

export function TemplateVariableSelector({
  contextValue,
  editor,
}: VariableSelectorProps) {
  const { t } = useTranslation(['templates', 'common']);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchString, setSearchString] = useState('');

  const getContext = contextValues(t).find(
    (value) => value.label === contextValue,
  );
  const { data: variables, isLoading } = useVariablesQuery({
    context: getContext?.value,
  });

  const filteredItems = useMemo(
    () =>
      searchString.length === 0
        ? variables
        : variables?.filter((variable) =>
            variable?.name?.toLowerCase().includes(searchString.toLowerCase()),
          ),
    [searchString, contextValue, variables],
  );

  const handleChipInsertion = ({ label, value }: ChipValueTypes) => {
    editor.chain().focus().addVariable({ label, value }).run();
  };

  const handleClose = () => {
    onClose();
    setSearchString('');
  };

  const id = 'simple-popper';

  return (
    <Box display="flex" alignItems="center" px={1}>
      <Tooltip title={t('common:templateVariables')} placement="bottom">
        <Button
          ref={anchorEl}
          variant="outlined"
          aria-describedby={id}
          aria-expanded={isOpen ? 'true' : undefined}
          aria-controls={isOpen ? 'simple-popper-menu' : undefined}
          aria-haspopup="true"
          endIcon={
            !isLoading && <ChevronDownIcon sx={{ color: 'slategrey' }} />
          }
          onClick={onOpen}
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #E2E8F0',
            justifyContent: 'space-between',
            minWidth: '195px',
          }}
        >
          {isLoading ? (
            <CircularProgress size={16} sx={{ mr: 1 }} />
          ) : (
            <Typography color="#1e293b" fontSize="14px">
              {t('templates:addVariable')}
            </Typography>
          )}
        </Button>
      </Tooltip>
      {/* biome-ignore lint/a11y/useValidAriaRole: Need to reset the default role */}
      <Popper
        open={isOpen}
        anchorEl={anchorEl.current}
        role={undefined}
        placement="bottom"
        disablePortal
        sx={{
          zIndex: 'modal',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            ref={setContainerRef}
            sx={({ palette }) => ({
              minHeight: 172,
              maxHeight: 'min(calc(100vh - 96px), 400px)',
              position: 'relative',
              overflow: 'auto',
              mt: 1,
              borderRadius: 1,
              boxShadow:
                '0 0 2px 0 rgba(100, 116, 139, 0.24),0px 20px 40px -4px rgba(100, 116, 139, 0.24)',
              backgroundColor: palette.background.paper,
            })}
          >
            <ListSubheader
              ref={headerRef}
              component={Stack}
              sx={{
                pt: 1,
                px: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(20px)',
              }}
            >
              <SearchInput
                value={searchString}
                onChange={(event) => {
                  setSearchString(event.target.value);
                }}
              />
            </ListSubheader>
            {variables?.length === 0 ? (
              <Stack
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 80,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  color="text.secondary"
                >
                  {t('common:noResultsFound')}
                </Typography>
              </Stack>
            ) : (
              containerRef && (
                <MenuList
                  variant="selectedMenu"
                  id={id}
                  aria-labelledby={id}
                  style={{
                    position: 'relative',
                  }}
                >
                  {filteredItems?.map((item) => (
                    <MenuItem
                      key={item?.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleChipInsertion({
                          label: item?.name ?? t('templates:default'),
                          value:
                            item?.value ?? t('templates:defaultLabelValue'),
                        });
                        handleClose();
                      }}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </MenuList>
              )
            )}
          </Stack>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
