import { useQuery } from '@tanstack/react-query';
import {
  AttendanceCodeType,
  type Attendance_RollBookFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { attendanceKeys } from './keys';

const rollBookAttendance = graphql(/* GraphQL */ `
  query attendance_rollBookAttendance(
    $filter: Attendance_RollBookFilter
  ) {
    attendance_rollBookAttendance(filter: $filter) {
      studentPartyId
      student {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        extensions {
          priority
          aen
        }
      }
      classGroup {
        name
      }
      dateAttendance {
        date
        eventAttendance {
          eventId
          date
          time
          attendanceCode {
            id
            name
            codeType
          }
          note
        }
      }
    }
  }
`);

const rollBookAttendanceQuery = (filter: Attendance_RollBookFilter) => ({
  queryKey: attendanceKeys.rollBookAttendance(filter),
  queryFn: async () => {
    const { attendance_rollBookAttendance: rollBookAttendanceData } =
      await gqlClient.request(rollBookAttendance, { filter });

    return rollBookAttendanceData.map((attendance) => {
      const noteByKey: Record<string, string | null> = {};
      const attendanceByKey: Record<string, string | null> = {};
      let lateCount = 0;

      for (const dateAttendance of attendance.dateAttendance ?? []) {
        const { date, eventAttendance = [] } = dateAttendance ?? {};
        for (const attendanceValue of eventAttendance) {
          const { eventId, attendanceCode } = attendanceValue ?? {};
          const key = `${date}-${eventId}`;

          lateCount += Number(
            attendanceCode.codeType === AttendanceCodeType.Late,
          );

          if (date && eventId && attendanceCode?.id) {
            attendanceByKey[key] = attendanceCode.name;

            if (attendanceValue?.note) {
              noteByKey[key] = attendanceValue.note;
            }
          }
        }
      }

      return {
        ...attendance,
        attendanceByKey,
        lateCount,
        noteByKey,
      };
    });
  },
});

export function getRollBookAttendance(filter: Attendance_RollBookFilter) {
  return queryClient.fetchQuery(rollBookAttendanceQuery(filter));
}

export function useRollBookAttendance(filter: Attendance_RollBookFilter) {
  return useQuery({
    ...rollBookAttendanceQuery(filter),
    select: (rollBookAttendanceData) => rollBookAttendanceData,
  });
}

export type ReturnTypeFromRollBookAttendance = UseQueryReturnType<
  typeof useRollBookAttendance
>[number];
