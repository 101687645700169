import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  RHFStudentAutocomplete,
  type StudentsSelectOption,
} from '@tyro/people';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { ReturnTypeFromUseTemplates } from '../../api/templates';

export type PrintTemplateData = {
  students: StudentsSelectOption;
  templateToGenerate?: ReturnTypeFromUseTemplates | null;
};

type SelectRecipientsModalProps = {
  isOpen: boolean;
  templateToGenerate?: ReturnTypeFromUseTemplates | null;
  setTemplateToGenerate?: Dispatch<
    SetStateAction<ReturnTypeFromUseTemplates | null>
  >;
  setOpenRecipientsModal?: Dispatch<SetStateAction<boolean>>;
  previewTemplate: (students: PrintTemplateData) => Promise<void>;
};

export function SelectRecipientsModal({
  isOpen,
  templateToGenerate,
  setTemplateToGenerate,
  setOpenRecipientsModal,
  previewTemplate,
}: SelectRecipientsModalProps) {
  const { t } = useTranslation(['templates', 'common']);
  const [students, setStudents] = useState<StudentsSelectOption>([]);
  const { resolver, rules } = useFormValidator<PrintTemplateData>();
  const { control, handleSubmit, reset } = useForm<PrintTemplateData>({
    resolver: resolver({
      students: rules.required(),
    }),
  });

  const handleClose = () => {
    setTemplateToGenerate?.(null);
    setOpenRecipientsModal?.(false);
  };

  const handlePreview = handleSubmit(async ({ students }) => {
    previewTemplate({
      students,
      templateToGenerate: templateToGenerate || null,
    });
  });

  useEffect(() => {
    if (isOpen) {
      reset({ students: [] });
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t('templates:templateStudentList')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('templates:selectStudentsForTemplate')}
        </DialogContentText>
        <RHFStudentAutocomplete
          fullWidth
          multiple
          optionIdKey="partyId"
          label={t('common:students')}
          onChange={(_e, value) => {
            setStudents(value as StudentsSelectOption);
          }}
          controlProps={{
            name: 'students',
            control,
          }}
          sx={{ marginTop: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" onClick={handleClose}>
          {t('common:actions.cancel')}
        </Button>

        <LoadingButton
          variant="contained"
          loading={false}
          onClick={handlePreview}
        >
          {t('common:actions.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
