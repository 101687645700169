import { Box } from '@mui/material';
import { TemplatingContextType } from '@tyro/api';
import { BaseMenuToolBar, useNumber, useTipTapEditor } from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import { EditLinkModal } from '@tyro/mail';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTemplates } from '../../api/templates';
import TemplateEditorInputField from './template-editor-input-field';
import { TemplateVariableSelector } from './template-variable-selector';

export type TemplateInformation = {
  recipient?: string;
  template?: string;
  templateType?: string;
  templateContent?: string | null;
};

export type ContextValuesType = {
  label: string;
  value: TemplatingContextType;
};

export const contextValues = (
  t: TFunction<['templates', 'common'], undefined, ['templates', 'common']>,
) => [{ label: t('common:student'), value: TemplatingContextType.Student }];

export function TemplateEditorContainer(
  templateInformation: TemplateInformation,
) {
  const { t } = useTranslation(['templates', 'common']);
  const { id } = useParams();
  const templateId = useNumber(id);
  const [contextValue, setContextValue] = useState<string>(
    templateInformation?.recipient ?? contextValues(t)[0]?.label,
  );

  const { data: template } = useTemplates({
    ids: [templateId ?? 0],
  });
  const editor = useTipTapEditor({});

  useEffect(() => {
    if (!editor) return;
    const letterTemplate = templateInformation.templateContent;
    const existingTemplate = template?.[0]?.template;
    if (existingTemplate) {
      editor.commands.setContent(existingTemplate || '');
    } else {
      editor.commands.setContent(letterTemplate || '');
    }
  }, [editor, template, templateInformation, templateId]);

  if (!editor) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 2,
        maxWidth: 'fit-content',
      }}
    >
      <BaseMenuToolBar
        editor={editor}
        contextValue={contextValue}
        variableSelector={
          <TemplateVariableSelector
            contextValue={contextValue}
            editor={editor}
          />
        }
        editLinkModal={({ isOpen, onClose }) => (
          <EditLinkModal editor={editor} isOpen={isOpen} onClose={onClose} />
        )}
      />
      <TemplateEditorInputField
        contextValue={contextValue}
        setContextValue={setContextValue}
        editor={editor}
        templateInformation={templateInformation}
        templateId={templateId}
      />
    </Box>
  );
}
