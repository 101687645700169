import dayjs, { type Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { useRollBookAttendance, useSaveAttendance } from '../../api';
import { useLessonTimes } from '../../api/calendar-lesson-times';
import { RoleBookTable } from './role-book-table';

interface LessonAttendanceRoleBookProps {
  groupId: number;
  partyIds: number[];
}

export function LessonAttendanceRoleBook({
  groupId,
  partyIds,
}: LessonAttendanceRoleBookProps) {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  const [from, to] = dateRange;
  const fromDate = from.format('YYYY-MM-DD');
  const toDate = to.format('YYYY-MM-DD');

  const { mutateAsync: saveEventAttendance } = useSaveAttendance();

  const { data: lessonTimesData = [], isLoading: isLessonTimesLoading } =
    useLessonTimes({
      resources: {
        partyIds: [groupId],
      },
      startDate: fromDate,
      endDate: toDate,
    });

  const { data: lessonAttendance, isLoading: isAttendanceLoading } =
    useRollBookAttendance({
      subjectGroupId: groupId,
      from: fromDate,
      to: toDate,
    });

  const attendanceTimes = useMemo(() => {
    return Object.entries(
      lessonTimesData.reduce(
        (acc, lessonTime) => {
          const date = dayjs(lessonTime.startTime).format('YYYY-MM-DD');

          acc[date] ??= [];
          acc[date].push({
            id: lessonTime.eventId,
            time: dayjs(lessonTime.startTime).format('hh:mm'),
          });

          return acc;
        },
        {} as Record<string, Array<{ id: number; time: string }>>,
      ),
    ).map(([date, times]) => ({ date, times }));
  }, [lessonTimesData]);

  const filteredLessonAttendance = useMemo(() => {
    if (partyIds.length === 0) return lessonAttendance;
    return lessonAttendance?.filter((lessonAttendance) =>
      partyIds.includes(lessonAttendance.studentPartyId),
    );
  }, [partyIds, lessonAttendance]);

  return (
    <RoleBookTable
      rowData={filteredLessonAttendance ?? []}
      getRowId={({ data }) => String(data?.studentPartyId)}
      isLoading={isAttendanceLoading || isLessonTimesLoading}
      dateRange={dateRange}
      setDateRange={setDateRange}
      attendanceTimes={attendanceTimes}
      isLessonRoleBook
      onBulkSave={(attendanceChanges) =>
        saveEventAttendance(
          attendanceChanges.map(
            ({ id, attendanceCodeId, partyId, date, note }) => ({
              adminSubmitted: false,
              eventId: id,
              attendanceCodeId,
              personPartyId: partyId,
              date,
              note,
            }),
          ),
        )
      }
    />
  );
}
