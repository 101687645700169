import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import type { SessionAttendanceFlag } from '@tyro/api';
import { useCalendarDayBellTimes } from '@tyro/calendar';
import { useTranslation } from '@tyro/i18n';
import dayjs, { type Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import {
  useSaveSessionAttendance,
  useSessionAttendance,
} from '../../api/session-attendance';
import { ApplyToSubjectSelect } from './apply-to-subject-select';
import { RoleBookTable } from './role-book-table';

interface SessionAttendanceRoleBookProps {
  partyIds: number[];
}

export function SessionAttendanceRoleBook({
  partyIds,
}: SessionAttendanceRoleBookProps) {
  const { t } = useTranslation(['common', 'attendance']);
  const [applyToSubjectAttendance, setApplyToSubjectAttendance] =
    useState<SessionAttendanceFlag | null>(null);

  const [
    overwriteExistingEventAttendance,
    setOverWriteExistingSubjectAttendance,
  ] = useState<boolean>(false);

  const { mutateAsync: saveSessionAttendance } = useSaveSessionAttendance();

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  const [from, to] = dateRange;
  const fromDate = from.format('YYYY-MM-DD');
  const toDate = to.format('YYYY-MM-DD');

  const { data: bellTimesData = [], isLoading: isBellTimesLoading } =
    useCalendarDayBellTimes({
      fromDate,
      toDate,
    });

  const { data: sessionData, isLoading: isAttendanceLoading } =
    useSessionAttendance({
      partyIds,
      from: fromDate,
      to: toDate,
    });

  const attendanceTimes = useMemo(() => {
    return bellTimesData
      .filter(({ bellTimes }) => bellTimes?.length)
      .map(({ date, bellTimes }) => ({
        date,
        times: bellTimes || [],
      }));
  }, [bellTimesData]);

  const resetSaveOptions = () => {
    setTimeout(() => {
      setApplyToSubjectAttendance(null);
      setOverWriteExistingSubjectAttendance(false);
    }, 2500);
  };

  return (
    <RoleBookTable
      rowData={sessionData ?? []}
      getRowId={({ data }) => String(data?.studentPartyId)}
      isLoading={isAttendanceLoading || isBellTimesLoading}
      dateRange={dateRange}
      setDateRange={setDateRange}
      attendanceTimes={attendanceTimes}
      onBulkSave={(attendanceChanges) =>
        saveSessionAttendance(
          {
            applyCodes: applyToSubjectAttendance,
            adminSubmitted: true,
            overwriteExistingEventAttendance,
            attendances: attendanceChanges.map(
              ({ id, attendanceCodeId, partyId, date, note }) => ({
                bellTimeId: id,
                attendanceCodeId,
                studentPartyId: partyId,
                date,
                note,
              }),
            ),
          },
          {
            onSuccess: resetSaveOptions,
          },
        )
      }
      onBulkSaveCanceled={resetSaveOptions}
      additionalEditBarElements={
        <Stack direction="row" spacing={3}>
          <ApplyToSubjectSelect
            value={applyToSubjectAttendance}
            onChange={setApplyToSubjectAttendance}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  overwriteExistingEventAttendance && !!applyToSubjectAttendance
                }
                disabled={applyToSubjectAttendance === null}
                onChange={(_e, checked) =>
                  setOverWriteExistingSubjectAttendance(checked)
                }
              />
            }
            label={t('attendance:overwriteExistingSubjectAttendance')}
          />
        </Stack>
      }
    />
  );
}
