import { Stack } from '@mui/material';
import { RHFTextField, Select } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { Dispatch, SetStateAction } from 'react';
import type { Control } from 'react-hook-form';
import { contextValues } from './template-editor-container';
import type { TemplateFormState } from './template-editor-input-field';

type TemplateEditorFormFieldsProps = {
  contextValue: string;
  setContextValue: Dispatch<SetStateAction<string>>;
  control: Control<TemplateFormState>;
};

export const TemplateEditorFormFields = ({
  contextValue,
  setContextValue,
  control,
}: TemplateEditorFormFieldsProps) => {
  const { t } = useTranslation(['templates']);
  const textFieldStyle = {
    maxWidth: 300,
    width: '50%',
    '& .MuiInputBase-input': {
      backgroundColor: '#FFF',
      borderRadius: 1,
    },
  };

  return (
    <Stack direction="row" spacing={1} mt={1} flexGrow={1}>
      <RHFTextField
        label={t('templates:nameOfTemplate')}
        textFieldProps={{ sx: textFieldStyle, multiline: true }}
        variant="white-filled"
        controlProps={{
          name: 'name',
          control,
        }}
      />

      <Select
        label={t('templates:contextOfTemplate')}
        fullWidth
        options={contextValues(t)}
        optionIdKey="label"
        getOptionLabel={(option) => option?.label}
        value={contextValue}
        variant="white-filled"
        onChange={(event) => {
          const newValue = event.target.value;
          setContextValue(newValue);
        }}
        sx={{
          maxWidth: 300,
          borderRadius: 1,
        }}
      />
    </Stack>
  );
};
